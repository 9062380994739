<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const { data, firstItemData, firstItemTimer } = useScretchCardData({ immediate: !isGuest.value });

const buttonName = computed(() => {
	if (isGuest.value) {
		return t("Create an Account");
	}
	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		return "Crack";
	}
	if (firstItemData.value.timer) {
		return firstItemTimer.value;
	}
	return t("Collect Free FC");
});

const handleCardClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/magic-boxes");
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleButtonClick = () => {
	loginGuard({
		success: () => {
			if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
				open("LazyOModalMagicBoxesSelection");
				return;
			}
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<ACard
		class="card-magic-boxes"
		width="292px"
		height="380px"
		:imgAttrs="{
			width: 292,
			height: 380,
			src: '/nuxt-img/magic-boxes/magic-pumpkins/promo-bg.png',
			format: 'avif',
			loading: 'lazy'
		}"
		alt="scratch-card"
		@click="handleCardClick"
	>
		<template v-if="data" #topLine>
			<MPromotionTimer :timeLeft="data?.finishedAt" />
		</template>
		<template #top>
			<MMagicBoxesSkinNameplate />
		</template>
		<template #bottom>
			<div class="text">
				<div class="prizes">
					<MPrizeFund variant="coins" icon="coins" is-svg :iconSize="28">
						<AText variant="tirana" class="prize-text" data-tid="promo-magic-box-coins" :modifiers="['semibold']">
							{{ numberFormat(Number(data?.prize?.coins || 750000)) }}
						</AText>
					</MPrizeFund>
					<MPrizeFund variant="entries" icon="entries" is-svg :iconSize="28">
						<AText variant="tirana" class="prize-text" data-tid="promo-magic-box-entries" :modifiers="['semibold']">
							{{ numberFormat(Number(data?.prize?.entries || 7500)) }}
						</AText>
						<AText class="free" :modifiers="['semibold', 'uppercase']"> {{ t("for free") }}</AText>
					</MPrizeFund>
				</div>
				<AText class="text-call text-primary-400" :size="18" :modifiers="['bold', 'center', 'uppercase']" as="div">
					{{ t("magic-boxes.skin.promo.description") }}
				</AText>
			</div>

			<MMagicBoxesSkinPumpkins />

			<AButton
				class="button"
				variant="primary"
				size="xl"
				data-tid="promo-magic-box"
				:modifiers="[!!firstItemData?.timer ? 'disabled' : '', 'wide']"
				@click.stop="handleButtonClick"
			>
				<AText variant="turin" :modifiers="['medium']">
					{{ buttonName }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-magic-boxes {
	width: 292px;
	height: 380px;
	.nameplate {
		margin: -25px 0 -10px;
		:deep(.date) {
			font-size: 11px;
		}
	}
	.text-call {
		margin-top: 8px;
	}
	.pumpkins {
		margin-bottom: -22px;
		:deep(.center) {
			width: 39%;
			left: 30%;
		}
	}
}

.prizes {
	text-align: center;
	.free {
		max-width: 36px;
	}
}
button.primary {
	position: relative;
	z-index: 2;
}
</style>
